$(document).ready(function () {
    $(".recurrent-task-execution-status-done").on('click', function () {
        var _this = $(this);
        var recurrentTaskExecutionId = _this.data('recurrent-task-execution-id');
        var toggleDoneUrl = _this.data('toggle-done-url');

        let nearestTr = _this.closest('tr');

        if (nearestTr) {
            nearestTr.addClass('background-color--lightgreen');
        }

        $.ajax({
            url: toggleDoneUrl,
            data: {
                'recurrentTaskExecutionId': recurrentTaskExecutionId,
            },
            error: function () {
                nearestTr.removeClass('background-color--lightgreen');
            }
        });
    });

    $(".recurrent-task-execution-status-skipped").on('click', function () {
        var _this = $(this);
        var recurrentTaskExecutionId = _this.data('recurrent-task-execution-id');
        var toggleSkipUrl = _this.data('toggle-skip-url');

        let nearestTr = _this.closest('tr');

        if (nearestTr) {
            nearestTr.addClass('background-color--lightyellow');
        }

        $.ajax({
            url: toggleSkipUrl,
            data: {
                'recurrentTaskExecutionId': recurrentTaskExecutionId,
            },
            error: function () {
                nearestTr.removeClass('background-color--lightyellow');
            }
        });
    });
});
